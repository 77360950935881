import React, { useCallback, useState, useRef } from "react"
import { Helmet } from "react-helmet"

import "./index.scss"

const content = [
  {
    text: 'Du er like fin i håndkle som i kjole',
    image: '/like_fin.jpg',
    buttonText: 'Videre',
    audio: '/audio/like_fin.m4a'
  },
  {
    text: 'Lykke barometeret er alltid på topp',
    image: '/lykke_01.jpg',
    buttonText: 'Videre',
    audio: '/audio/lykke.m4a'
  },
  {
    text: '... hvertfall nesten',
    image: '/sint_01.jpg',
    buttonText: 'Videre',
    audio: '/audio/sint.m4a'
  },
  {
    text: 'Alltid sulten på hud',
    image: '/hudkontakt_01.jpg',
    buttonText: 'Videre',
    audio: '/audio/hudkontakt.m4a'
  },
  {
    text: 'Ganske sterk',
    image: '/sterk_01.jpg',
    buttonText: 'Videre',
    audio: '/audio/sterk.m4a'
  },
  {
    text: 'Topp 900 i Norge på å lage mat',
    image: '/lage_mat_01.jpg',
    buttonText: 'Videre',
    audio: '/audio/lage_mat.m4a'
  },
  {
    text: 'Topp 9 i Norge på å spise mat',
    image: '/mat_01.jpg',
    buttonText: 'Videre',
    audio: '/audio/spise_mat.m4a'
  },
  {
    text: 'Frisk og oppegående',
    image: '/frisk.jpg',
    buttonText: 'Videre',
    audio: '/audio/frisk.m4a'
  },
  {
    text: 'Med noen unntak',
    image: '/ikkefrisk_01.jpg',
    buttonText: 'Videre',
    audio: '/audio/ikke_frisk.m4a'
  },
  {
    text: 'Men mest frisk. Gratulerer så mye med dagen!!',
    image: '/mest_frisk_01.jpg',
    buttonText: false,
    audio: '/audio/mest_frisk.m4a'
  },
]

export default () => {
  const [index, setIndex] = useState(-1);
  const audioRef0 = useRef();
  const audioRef1 = useRef();
  const audioRef2 = useRef();
  const audioRef3 = useRef();
  const audioRef4 = useRef();
  const audioRef5 = useRef();
  const audioRef6 = useRef();
  const audioRef7 = useRef();
  const audioRef8 = useRef();
  const audioRef9 = useRef();
  const audioRef10 = useRef();

  const nextIndex = useCallback(() => {
    if (index < content.length - 1) setIndex(index + 1);

    if (index === -1) audioRef1.current.play();
    if (index === 0) audioRef2.current.play();
    if (index === 1) audioRef3.current.play();
    if (index === 2) audioRef4.current.play();
    if (index === 3) audioRef5.current.play();
    if (index === 4) audioRef6.current.play();
    if (index === 5) audioRef7.current.play();
    if (index === 6) audioRef8.current.play();
    if (index === 7) audioRef9.current.play();
    if (index === 8) audioRef10.current.play();
  }, [setIndex, index]);

  const soundCheck = useCallback(() => {
    audioRef0.current.play();
  }, [audioRef1]);

  return (
    <div className="bday-container">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
        <title>Gratulerer med dagen 03.03.2022</title>
      </Helmet>
        {index === -1 && (
          <div>
            <h1 className="title">Agnes oppsummert</h1>
            <em>Livet gjennom linsa</em>
            <br />
            <br />
            <br />
            <br />
            <br />
            <button className="next-content-btn" onClick={soundCheck}>Sound check</button>
            <button className="start-content-btn" onClick={nextIndex}>Gratuler meg med livet</button>
          </div>
        )}
        {index >= 0 && (
          <>
            <h1 className="title">Agnes oppsummert</h1>
            <em>Livet gjennom linsa</em>
            <div className="contentWrapper">
              <h3>{content[index].text}</h3>
              <img src={content[index].image} className="contentImage" alt=""/>
            </div>
            {content[index].buttonText && <button className="next-content-btn" onClick={nextIndex}>{content[index].buttonText}</button>}
          </>
        )}
      <audio ref={audioRef0}>
        <source src="/audio/sound_check.m4a" type="audio/ogg" />
        <source src="/audio/sound_check.m4a" type="audio/mp4" />
      </audio>
      <audio ref={audioRef1}>
        <source src={content[0].audio} type="audio/ogg" />
        <source src={content[0].audio} type="audio/mp4" />
      </audio>
      <audio ref={audioRef2}>
        <source src={content[1].audio} type="audio/ogg" />
        <source src={content[1].audio} type="audio/mp4" />
      </audio>
      <audio ref={audioRef3}>
        <source src={content[2].audio} type="audio/ogg" />
        <source src={content[2].audio} type="audio/mp4" />
      </audio>
      <audio ref={audioRef4}>
        <source src={content[3].audio} type="audio/ogg" />
        <source src={content[3].audio} type="audio/mp4" />
      </audio>
      <audio ref={audioRef5}>
        <source src={content[4].audio} type="audio/ogg" />
        <source src={content[4].audio} type="audio/mp4" />
      </audio>
      <audio ref={audioRef6}>
        <source src={content[5].audio} type="audio/ogg" />
        <source src={content[5].audio} type="audio/mp4" />
      </audio>
      <audio ref={audioRef7}>
        <source src={content[6].audio} type="audio/ogg" />
        <source src={content[6].audio} type="audio/mp4" />
      </audio>
      <audio ref={audioRef8}>
        <source src={content[7].audio} type="audio/ogg" />
        <source src={content[7].audio} type="audio/mp4" />
      </audio>
      <audio ref={audioRef9}>
        <source src={content[8].audio} type="audioogg4" />
        <source src={content[8].audio} type="audio/mp4" />
      </audio>
      <audio ref={audioRef10}>
        <source src={content[9].audio} type="audioogg4" />
        <source src={content[9].audio} type="audio/mp4" />
      </audio>
    </div>
  );
};
